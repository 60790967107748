module.exports = {
    // User
    saveUser: "/api/users",
    authUser: "/api/auth",
    updateUser: "/api/users/update",
    changeUserPassword: "/api/users/change-password",
    activationUser: "/api/users/activation-user",
    getUserByEmail: "/api/users/email/",
    getUserByPasswordToken: "/api/users/password-token/",
    getUserByActivationToken: "/api/users/activation-token/",
    addRemoveUserSubscription: "/api/users/subscribe-user/",
    deleteUserAccount: "/api/users/delete-account",
    getUserProjectRoles: "/api/users/get-roles/",
    // Profiles
    getUserProfile: "/api/profiles/me",
    createProfile: "/api/profiles",
    addProfileRating: "/api/profiles/rating",
    removeProfileRating: "/api/profiles/rating/",
    addRecentlyViewedFile: "/api/profiles/recently-viewed",
    addFavorite: "/api/profiles/favorites",
    updateNotificationSettings: "/api/profiles/notification-settings",
    getProfileNotifications: "/api/profiles/notifications",
    // Subscriptions
    getAllSubscriptions: "/api/subscriptions",
    getSubscriptionByCode: "/api/subscriptions/code/",
    // Permissions
    getAllPermissions: "/api/permissions",
    getPermissionByCode: "/api/permissions/code/",
    // Projects
    createProject: "/api/projects",
    updateProject: "/api/projects/update",
    checkProjectCreation: "/api/projects/check-creation/",
    getProjectById: "/api/projects/id/",
    addProjectFile: "/api/projects/file",
    deleteProject: "/api/projects/",
    deleteProjectFile: "/api/projects/file/",
    copyProjectFile: "/api/projects/copy-file",
    moveProjectFile: "/api/projects/moving-file/",
    projectInviteUsers: "/api/projects/invite-users",
    projectDeleteInvitation: "/api/projects/invitation/",
    joinProject: "/api/projects/join-project/",
    refuseJoinProject: "/api/projects/join-project-refused/",
    updateProjectUserRole: "/api/projects/update-user-role",
    updateProjectUserVisa: "/api/projects/update-user-visa",
    removeProjectMember: "/api/projects/member/",
    getProjectParents: "/api/projects/parents/",
    moveProject: "/api/projects/moving-project/",
    getUserProjects: "/api/projects/user",
    getProjectSize: "/api/projects/size/",
    getProjectFiles: "/api/projects/files/",
    getProjectActivities: "/api/projects/activities/",
    getProjectMembers: "/api/projects/members/",
    getProjectMembersTimesheet: "/api/projects/membersTimesheet/",
    getProjectAuthorUsedStorage: "/api/projects/used-storage/",
    searchProject: "/api/projects/search",
    // Project files
    getProjectFileById: "/api/project-files/id/",
    loadProjectFile: "/api/project-files/load/",
    updateProjectFile: "/api/project-files/update",
    unlockProjectFile: "/api/project-files/unlock",
    removeFileComment: "/api/project-files/comment/",
    getProjectFileSize: "/api/project-files/size/",
    incrementFileViews: "/api/project-files/increment-views/",
    getFilePresignedUrl: "/api/project-files/presigned-url/",
    getFileActivities: "/api/project-files/activities/",
    getFileComments: "/api/project-files/comments/",
    getFileRelatedCompiled: "/api/project-files/related-compiled/",
    getProcessedFiles: "/api/project-files/processed-files/",
    checkIsProcessedFile: "/api/project-files/check-processed/",
    // Emails
    emailResetPassword: "/api/emails/reset-password",
    emailActivateAccount: "/api/emails/activate-account",
    emailContact: "/api/emails/contact",
    emailSubscriptionUpdate: "/api/emails/subscription-update",
    emailSubscriptionTermination: "/api/emails/subscription-termination",
    emailCreatedProject: "/api/emails/created-project",
    emailSharedFile: "/api/emails/shared-file",
    // Images
    getImageByCode: "/api/images/code/",
    // Languages
    getAllLanguages: "/api/languages",
    getLanguageByCode: "/api/languages/code/",
    // Payments
    createStripeSubscription: "/api/payments/sub",
    createPayPalAgreement: "/api/payments/paypal-agreement",
    createPayPalOrder: "/api/payments/create-paypal-order",
    getPaymentPlatformByCode: "/api/payments/code/",
    getAllPaymentsPlatforms: "/api/payments",
    updateUserCard: "/api/payments/update-user-card",
    updateUserSubscription: "/api/payments/update-user-subscription",
    // Payments transactions
    savePaymentTransaction: "/api/payment-transactions/",
    getSellerPaymentTransactions: "api/payment-transactions/seller",
    saveSubscriptionTransaction: "/api/subscription-transactions/",
    getUserSubscriptionTransactions: "/api/subscription-transactions/user/",
    getSubscriptionTransactionInvoice:
        "/api/subscription-transactions/generate-invoice/",
    // Networks
    getAllNetworks: "/api/networks",
    // Texts
    getTextByCode: "/api/texts/code/",
    // Cookies
    getAllCookies: "/api/cookies",
    getCookieByCode: "/api/cookies/code/",
    // Cookie groups
    getAllCookieGroups: "/api/cookie-groups",
    getCookieGroupByCode: "/api/cookie-groups/code/",
    // Settings
    getSettingByCode: "/api/settings/code/",
    // Features
    getAllFeatures: "/api/features",
    // Jobs
    getAllJobs: "/api/jobs",
    // Beta emails
    addBetaEmail: "/api/beta-emails",
    getNbBetaEmails: "/api/beta-emails/count",
    checkBetaEmail: "/api/beta-emails/check-email/",
    checkBetaToken: "/api/beta-emails/check-token/",
    // Newsletter
    subscribeNewsletter: "/api/newsletter/subscribe",
    unsubscribeNewsletter: "/api/newsletter/unsubscribe/",
    // Faq
    getAllFaqs: "/api/faqs",
    // Commentaires
    createComment: "/api/comments",
    updateComment: "/api/comments",
    addSubComment: "/api/comments/add",
    deleteComment: "/api/comments/",
    handleCommentReaction: "/api/comments/reaction/",
    // Priorités
    getAllPriorities: "/api/priorities",
    getPriorityById: "/api/priorities/id/",
    // Roles
    getAllRoles: "/api/roles",
    // Landings
    getLandingByCode: "/api/landings/code/",
    // Testimonials
    getAllTestimonials: "/api/testimonials",
    // Institutionals
    getInstitutionalByCode: "/api/institutionals/code/",
    // Notifications
    markNotificationAsRead: "/api/notifications/read/",
    markAllNotificationsAsRead: "/api/notifications/all-read",

    // Timesheet Projects
    getTimesheetProjects: "/api/timesheet/projects/",
    updateTimesheetProject: "/api/timesheet/project/update/",
    getTeamTimesheetProject: "/api/timesheet/projects/team/",
    // Timesheet TimeEntries
    getFilteredTimeEntries: "/api/timesheet/entries/",
    createTimesheetEntry: "/api/timesheet/entries/create/",
    updateTimesheetEntry: "/api/timesheet/entries/update/",
    sendTimesheetEntries: "/api/timesheet/entries/send/",
    deleteTimesheetEntries: "/api/timesheet/entries/delete/",
    checkExistingHoursTimesheetEntries:
        "/api/timesheet/entries/check-existing-hours/",

    getAllowedProjectTasks: "/api/timesheet/entries/projectsTasks/",
    getMyTimeEntries: "/api/timesheet/entries/me/",
    getTimeWorkedTimeEntries: "/api/timesheet/entries/time/",
    getPrevDayEntries: "/api/timesheet/entries/prev/",
    // Timesheet Tasks
    getTimesheetTasks: "/api/timesheet/tasks/",
    createTimesheetTask: "/api/timesheet/tasks/create/",
    updateTimesheetTask: "/api/timesheet/tasks/update/",
    removeTimesheetTask: "/api/timesheet/tasks/delete/",
    // Timesheet Fields
    getTimesheetFields: "/api/timesheet/fields/",
    getTimesheetFieldsFromTask: "/api/timesheet/fields/task/",
    createTimesheetField: "/api/timesheet/fields/create/",
    updateTimesheetField: "/api/timesheet/fields/update/",
    removeTimesheetField: "/api/timesheet/fields/delete/",
    orderTimesheetField: "/api/timesheet/fields/order/",
    // Customers
    getCustomers: "/api/customers/",
    createCustomer: "/api/customers/create/",
    updateCustomer: "/api/customers/update/",
    removeCustomer: "/api/customers/delete/",
    // Absences
    getAbsences: "/api/absences/",
    getAbsencesValidations: "/api/absences/validations/",
    getAbsencesAvailables: "/api/absences/availables/",
    createAbsence: "/api/absences/create/",
    updateAbsence: "/api/absences/update/",
    removeAbsence: "/api/absences/delete/",
    // ScheduleConfiguration
    getScheduleConfiguration: "/api/scheduleConfiguration/",
    resetScheduleConfiguration: "/api/scheduleConfiguration/reset",
    // Automations
    getAllAutomationTriggerTypes: "/api/automation-trigger-types",
    getAllAutomationTriggerActions: "/api/automation-trigger-actions",
    getAllAutomationUserTypes: "/api/automation-user-types",
    getAllAutomationConditionTypes: "/api/automation-condition-types",
    getAllAutomationActionTypes: "/api/automation-action-types",
    getAllAutomationActionConstraints: "/api/automation-action-constraints",
    createAutomation: "/api/automations",
    updateAutomation: "/api/automations/update",
    getUserAutomations: "/api/automations/user",
    deleteAutomation: "/api/automations/",
    duplicateAutomation: "/api/automations/duplicate/",
    // Visas
    createVisa: "/api/visas",
    updateVisa: "/api/visas/update",
    getFolderVisas: "/api/visas/folder/",
    deleteVisa: "/api/visas/",
    getUserVisas: "/api/visas/user/",
    // Customer Configs
    getCustomerConfiguration: "/api/customer-configs/",
};
