import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import Spinner from "./components/utils/Spinner";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback={<Spinner />}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Suspense>
);
